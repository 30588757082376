// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Table of Contents
//
// Color system
// Options
// Spacing
// Body
// Links
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components
// Tables
// Buttons
// Forms
// Dropdowns
// Z-index master list
// Navs
// Navbar
// Pagination
// Jumbotron
// Form states and alerts
// Cards
// Tooltips
// Popovers
// Badges
// Modals
// Alerts
// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs
// Carousel
// Close
// Code


//
// Color system
//

$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:  #000 !default;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900
) !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
) !default;

$theme-colors: (
  primary: $blue,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
) !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            true !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
) !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
) !default;

// Body
//
// Settings for the `<body>` element.

$body-bg:       $white !default;
$body-color:    $gray-900 !default;

// Links
//
// Style anchor elements.

$link-color:            theme-color("primary") !default;
$link-decoration:       none !default;
$link-hover-color:      darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:         1.5 !default;
$line-height-sm:         1.5 !default;

$border-width: 1px !default;

$border-radius:          .25rem !default;
$border-radius-lg:       .3rem !default;
$border-radius-sm:       .2rem !default;

$component-active-color: $white !default;
$component-active-bg:    theme-color("primary") !default;

$caret-width:            .3em !default;

$transition-base:        all .2s ease-in-out !default;
$transition-fade:        opacity .15s linear !default;
$transition-collapse:    height .35s ease !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:   1.25rem !default;
$font-size-sm:   .875rem !default;

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: 2.5rem !default;
$h2-font-size: 2rem !default;
$h3-font-size: 1.75rem !default;
$h4-font-size: 1.5rem !default;
$h5-font-size: 1.25rem !default;
$h6-font-size: 1rem !default;

$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family:   inherit !default;
$headings-font-weight:   500 !default;
$headings-line-height:   1.1 !default;
$headings-color:         inherit !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight:     300 !default;
$display2-weight:     300 !default;
$display3-weight:     300 !default;
$display4-weight:     300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size:   1.25rem !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-600 !default;

$blockquote-small-color:  $gray-600 !default;
$blockquote-font-size:    ($font-size-base * 1.25) !default;

$hr-border-color: rgba($black,.1) !default;
$hr-border-width: $border-width !default;

$mark-padding: .2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow:         inset 0 -.1rem 0 rgba($black,.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 5px !default;

$mark-bg: #fcf8e3 !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            .75rem !default;
$table-cell-padding-sm:         .3rem !default;

$table-bg:                      transparent !default;
$table-accent-bg:               rgba($black,.05) !default;
$table-hover-bg:                rgba($black,.075) !default;
$table-active-bg:               $table-hover-bg !default;

$table-border-width:            $border-width !default;
$table-border-color:            $gray-200 !default;

$table-head-bg:                 $gray-200 !default;
$table-head-color:              $gray-700 !default;

$table-inverse-bg:              $gray-900 !default;
$table-inverse-accent-bg:       rgba($white, .05) !default;
$table-inverse-hover-bg:        rgba($white, .075) !default;
$table-inverse-border-color:    lighten($gray-900, 7.5%) !default;
$table-inverse-color:           $body-bg !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$input-btn-padding-y:       .5rem !default;
$input-btn-padding-x:       .75rem !default;
$input-btn-line-height:     1.25 !default;

$input-btn-padding-y-sm:    .25rem !default;
$input-btn-padding-x-sm:    .5rem !default;
$input-btn-line-height-sm:  1.5 !default;

$input-btn-padding-y-lg:    .5rem !default;
$input-btn-padding-x-lg:    1rem !default;
$input-btn-line-height-lg:  1.5 !default;

$btn-font-weight:                $font-weight-normal !default;
$btn-box-shadow:                 inset 0 1px 0 rgba($white,.15), 0 1px 1px rgba($black,.075) !default;
$btn-focus-box-shadow:           0 0 0 3px rgba(theme-color("primary"), .25) !default;
$btn-active-box-shadow:          inset 0 3px 5px rgba($black,.125) !default;

$btn-link-disabled-color:        $gray-600 !default;

$btn-block-spacing-y:            .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:              $border-radius !default;
$btn-border-radius-lg:           $border-radius-lg !default;
$btn-border-radius-sm:           $border-radius-sm !default;

$btn-transition:                 all .15s ease-in-out !default;


// Forms

$input-bg:                       $white !default;
$input-disabled-bg:              $gray-200 !default;

$input-color:                    $gray-700 !default;
$input-border-color:             rgba($black,.15) !default;
$input-btn-border-width:         $border-width !default; // For form controls and buttons
$input-box-shadow:               inset 0 1px 1px rgba($black,.075) !default;

$input-border-radius:            $border-radius !default;
$input-border-radius-lg:         $border-radius-lg !default;
$input-border-radius-sm:         $border-radius-sm !default;

$input-focus-bg:                 $input-bg !default;
$input-focus-border-color:       lighten(theme-color("primary"), 25%) !default;
$input-focus-box-shadow:         $input-box-shadow, $btn-focus-box-shadow !default;
$input-focus-color:              $input-color !default;

$input-placeholder-color:        $gray-600 !default;

$input-height-border:           $input-btn-border-width * 2 !default;

$input-height-inner:            ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2) !default;
$input-height:                  calc(#{$input-height-inner} + #{$input-height-border}) !default;

$input-height-inner-sm:         ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;
$input-height-sm:               calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;

$input-height-inner-lg:         ($font-size-sm * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2) !default;
$input-height-lg:               calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;

$input-transition:               border-color ease-in-out .15s, box-shadow ease-in-out .15s !default;

$form-text-margin-top:     .25rem !default;

$form-check-margin-bottom:  .5rem !default;
$form-check-input-gutter:   1.25rem !default;
$form-check-input-margin-y: .25rem !default;
$form-check-input-margin-x: .25rem !default;

$form-check-inline-margin-x: .75rem !default;

$form-group-margin-bottom:       1rem !default;

$input-group-addon-bg:           $gray-200 !default;
$input-group-addon-border-color: $input-border-color !default;

$custom-control-gutter:   1.5rem !default;
$custom-control-spacer-y: .25rem !default;
$custom-control-spacer-x: 1rem !default;

$custom-control-indicator-size:       1rem !default;
$custom-control-indicator-bg:         #ddd !default;
$custom-control-indicator-bg-size:    50% 50% !default;
$custom-control-indicator-box-shadow: inset 0 .25rem .25rem rgba($black,.1) !default;

$custom-control-indicator-disabled-bg:       $gray-200 !default;
$custom-control-description-disabled-color:  $gray-600 !default;

$custom-control-indicator-checked-color:      $white !default;
$custom-control-indicator-checked-bg:         theme-color("primary") !default;
$custom-control-indicator-checked-box-shadow: none !default;

$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg, 0 0 0 3px theme-color("primary") !default;

$custom-control-indicator-active-color:      $white !default;
$custom-control-indicator-active-bg:         lighten(theme-color("primary"), 35%) !default;
$custom-control-indicator-active-box-shadow: none !default;

$custom-checkbox-indicator-border-radius: $border-radius !default;
$custom-checkbox-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-checkbox-indicator-indeterminate-bg: theme-color("primary") !default;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-checkbox-indicator-indeterminate-box-shadow: none !default;

$custom-radio-indicator-border-radius: 50% !default;
$custom-radio-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3E%3C/svg%3E"), "#4-4-4z'/%3E%3C/svg%3E"), "#", "%23") !default;

$carousel-transition:           transform .6s ease !default;


// Close

$close-font-size:             $font-size-base * 1.5 !default;
$close-font-weight:           $font-weight-bold !default;
$close-color:                 $black !default;
$close-text-shadow:           0 1px 0 $white !default;

// Code

$code-font-size:              90% !default;
$code-padding-y:              .2rem !default;
$code-padding-x:              .4rem !default;
$code-color:                  #bd4147 !default;
$code-bg:                     $gray-100 !default;

$kbd-color:                   $white !default;
$kbd-bg:                      $gray-900 !default;

$pre-color:                   $gray-900 !default;
$pre-scrollable-max-height:   340px !default;
